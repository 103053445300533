import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';  
import { useQuery, useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import {
    from, HttpLink
  } from "@apollo/client";  
import { createUploadLink } from "apollo-upload-client";

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    }
  }
});

var uploadLink = createUploadLink({uri: 'https://api.publicface.space/api'})

const wsLink = new GraphQLWsLink(createClient({
  url: 'https://api.publicface.space/subscriptions',
  connectionParams: {
    authentication: localStorage.getItem('token'),
  },
}));

const client = new ApolloClient({
  link: from([authLink, uploadLink, wsLink]),  
  cache: new InMemoryCache(),
});

const PUBLICFACESPAGE = gql`query PublicFacesPage($startKey: DateTime, $page: Int, $sorting: String, $sortingdesc: String,  $itemsCount: Int, $onlyPublicFaces: Boolean, $onlyActived: Boolean, $search: String, $searchCountry: String) 
{ publicfaces (startKey: $startKey, page: $page, sorting: $sorting, sortingdesc: $sortingdesc,  itemsCount: $itemsCount, onlyPublicFaces: $onlyPublicFaces, onlyActived: $onlyActived, search: $search, searchCountry: $searchCountry) 
    {hasNext lastKey total 
        items {id avatar phone nickname registerDateTime lastVisitDateTime f i o nickname bindedProfile {id} osphone rating likes dislikes views Actived Online Verified PublicFace 
        country { id Country_ru Country_eng  Country_phonecode} 
        pushdelivery
        lastDiscussion { id discussion video audio preview createdAt amplitudes } 
        lastComment {  id message createdAt video audio amplitudes }    } } }`;

const PublicFaceList = ({count}:{count:number}) => {
    var [publicFaces, SetPublicFaces] = useState<any>([]);


    const [LoadPuglicFacesList] = useLazyQuery(PUBLICFACESPAGE, 
        {            
            client:client,
            onCompleted: (data:any) =>
            {
                SetPublicFaces(data.publicfaces.items);
            }, 
            onError: (error:any) =>
            {
                //SetError(error.message);
            }
    })

    useEffect(() => 
    {
        LoadPuglicFacesList({variables: {startKey: new Date().toISOString(), page: 1, sorting:"registerDateTime", sortingdesc:"ascending", onlyActived: true, itemsCount: count, onlyPublicFaces: true}});
    }, [])

    return  <div className='row'>
                {publicFaces?.map((item:any, index:number)=>
                {
                    return <div className='col-xs-6 col-sm-6 col-md-3 col-lg-2 pb-3'>
                            <img className='rounded-circle avatar' src={`https://images.publicface.space/avatars/${item?.id}/${item?.avatar}`} />
                            <p>{item?.nickname || item?.f + " " + item?.i}</p>
                        </div>
                }) }
            </div>
}


export default PublicFaceList