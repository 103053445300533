import React from 'react';
import logo from './logo.svg';
import './App.css';
import PublicFaceList from "./PublicFaceList";
import rustore from "./rustore.png";

function App() {
  return (
    <div className="App">
    <div className="container">
    
      <div className='header text-center pt-5'>
          <div className='d-flex align-items-center justify-content-center'>
            <span className='logo d-flex align-items-center justify-content-center' style={{fontWeight: "bold"}}>
              Pf
            </span>

            <span className='LogoText'>public face</span>
          </div>
      </div>

      <div className='row pt-5'>
          <div  className='col-xl-10 col-sm-12'>

          <p className='Description'>
            Сервис, в котором у пользователей есть возможность делиться своим мнением и обсуждать знаменитых людей:
            блогеров, музыкантов, спортсменов, кинозвезд и т.д.
          </p>
          </div>

          <div className='col-xl-2 col-sm-12 appimg1'>
            <img src="/pf1.jpg" style={{width:"250px", borderRadius:20}} />
          </div>
      </div>  

      <div className='pt-5 row'>
      <PublicFaceList count={54} />
      </div>

      <div className='pt-5'>
        <div className='row'>
               <div className='col-md-4 mt-3'>
                        <button className="btn btn-outline-dark btn-icon-text w-100">
                            <i className="fa-sharp fa-solid fa-3x fa-apple-whole" style={{marginRight:15}}></i>
                            <span className="d-inline-block text-left">
                              <small className="font-weight-light d-block">Download on the</small>
                              <h1>App Store</h1>
                            </span>
                        </button>
                </div>

                <div className='col-md-4 mt-3'>
                        <button className="btn btn-outline-dark btn-icon-text w-100">
                            <i className="fa-sharp fa-solid fa-3x fab fa-google-play" style={{marginRight:15}}></i>
                            <span className="d-inline-block text-left">
                              <small className="font-weight-light d-block">Download on the</small>
                              <h1>Google play</h1>
                            </span>
                        </button>
                </div>

                <div className='col-md-4 mt-3'>
                          <button className="btn btn-outline-dark btn-icon-text w-100 d-flex align-items-center justify-content-center">
                            <img src={rustore} style={{marginRight:15, width: 50, height:50}} />
                            <span className="d-inline-block text-left">
                              <small className="font-weight-light d-block">Download on the</small>
                              <h1>RuStore</h1>
                            </span>
                          </button>
                </div>
        </div>
      </div>
        
      <div className='footer pt-5 pb-5' style={{fontSize:25}}>
          <div className='row'>
              <div className='col-md-4'> 
               ооо "Терабайт"  
              </div>
              
              <div className='col-md-4'> 
                ОГРН 1220100001571  
              </div>
              
              <div className='col-md-4'> 
               ИНН 100002097
              </div>
          </div>
      </div>

    </div>
    </div>
  );
}

export default App;
